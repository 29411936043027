<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <section class="header point_history">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
        </a>
        <span class="fL">交易紀錄</span>
        <div class="icon iconButton"></div>
      </section>
      <div class="uide-tabs main-top">
        <a class="uide-tab  " @click.prevent="$router.push('/record')">
          <span class="fM">獲得紀錄</span>
        </a>
        <a class="uide-tab active">
          <span class="fM">兌換紀錄</span>
        </a>
        <a class="uide-tab " @click.prevent="$router.push('/transfer-record')">
          <span class="fM">轉出紀錄</span>
        </a>
      </div>

      <div class="uide-header-bottom-wrap">

        <div class="uide-filter-wrap" @click.stop>
          <a class="uide-filter" @click.prevent="timeFilterOpen = true">
            <span class="">{{ displayedTimeFilterOption }}</span>
            <div class="icon ">
              <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
            </div>
          </a>
        </div>
      </div>
      <!-- Main -->
      <section class="main point_history">
        <!-- 紀錄 -->

        <div class="records flexV width padding">
          <div v-if="!transactions.records || transactions.records.length == 0" class="text-center flex gap-6 item-center py-4 flex-col">
            <img width="160" height="160" src="@/assets/icon/page-empty-content.svg" />
            <div class="fL font-medium  cNeutral500 text-center">您目前尚未有兌換紀錄</div>
          </div>
          <!-- arnold-todo 只作樣板 -->
          <div class="flex flex-row bg-white py-4 px-4 rounded-2xl " @click.prevent="$router.push('/payment-record-detail')">
            <div class="flex-7 flex flex-col gap-1">
              <div class="text-lg text-c03">
                兌換扣除雲林幣5枚
              </div>
              <div class="cNeutral500">
                2024/04/15 10:21:48
              </div>
            </div>
            <div class="flex-3 flex justify-right">
              <img width="24px" src="@/assets/icon/uide/chevron-01.svg" />
            </div>
          </div>
          <div class="flex flex-row bg-white py-4 px-4 rounded-2xl " @click.prevent="$router.push('/payment-record-detail')">
            <div class="flex-7 flex flex-col gap-1">
              <div class="text-lg text-c03">
                兌換扣除雲林幣6枚
              </div>
              <div class="cNeutral500">
                2024/04/17 10:21:48
              </div>
            </div>
            <div class="flex-3 flex justify-right">
              <img width="24px" src="@/assets/icon/uide/chevron-01.svg" />
            </div>
          </div>
          <!-- arnoldtodo 需復原 -->
          <!-- <a
            class="record flexH width margin"
            v-for="(order, index) in displayedOrders"
            :key="'order' + index"
            @click.prevent="showOrderDetail(order.orderId)"
          >
            <div
              v-if="order.paymentMethod === 'offline'"
              class="icon type black"
            >
              <font-awesome-icon
                icon="fa-solid fa-money-bill-wave"
                size="xl"
              ></font-awesome-icon>
            </div>
            <div
              v-else-if="
                getPaymentImage(order.paymentService, order.paymentType)
              "
              class="img type"
            >
              <img
                :src="getPaymentImage(order.paymentService, order.paymentType)"
              />
            </div>
            <div v-else class="icon type black">
              <font-awesome-icon
                icon="fa-solid fa-credit-card"
                size="xl"
              ></font-awesome-icon>
            </div>
            <div class="flexV width">
              <span class="fM">{{ order.storeName }}</span>
              <span class="fS gray">{{ displayOrderTime(order) }}</span>
              <span v-if="order.status !== 'done'" class="fS error">
                已取消
              </span>
            </div>
            <div class="flexV end">
              <span v-if="order.paymentAmount" class="fM"
                >NT${{ order.paymentAmount }}</span
              >
              <div v-if="order.points" class="flexH">
                <div class="img"><img src="@/assets/icon/point.svg" /></div>
                <span class="fM primary">{{ order.points }}</span>
              </div>
            </div>
            <div class="icon minor">
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
              ></font-awesome-icon>
            </div>
          </a> -->
        </div>
      </section>
      <section class="footer point_history">
        <a class="button rounded" @click.prevent="$router.push('/scanner')">
          <div class="icon">
            <img src="@/assets/icon/scanner.svg" />
          </div>
          <span class="fM">使用紅利點數</span>
        </a>
      </section>
      <!-- Popup (時間區間) -->
      <FilterPopup v-if="timeFilterOpen" @closePopup="timeFilterOpen = false" v-slot="pop" :popTime="true">
        <section class="popHeader">
          <span class="fL">時間區間</span>
        </section>
        <section class="popMain">
          <!-- Tabs -->
          <div class="tabs rounded fS">
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Weekly' },
            ]" @click.prevent="changeTimeFilterOption('Weekly')">
              <span>近一週</span>
            </a>
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Monthly' },
            ]" @click.prevent="changeTimeFilterOption('Monthly')">
              <span>近一個月</span>
            </a>
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Yearly' },
            ]" @click.prevent="changeTimeFilterOption('Yearly')">
              <span>近一年</span>
            </a>
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Customize' },
            ]" @click.prevent="changeTimeFilterOption('Customize')">
              <span>自訂時間</span>
            </a>
          </div>
          <!-- Date -->
          <div class="input rounded" v-show="timeFilterOption == 'Customize'">
            <!-- class: fake / error -->
            <input :class="['fM', 'date', { error: selectStartErr }]" type="date" name="startDate" v-model="selectStart"
              @change="selectStartChange" />
            <!-- <input class="fM time" type="time" name="startTime"> -->
            <span class="fL">-</span>
            <input :class="['fM', 'date', { error: selectEndErr }]" type="date" name="endDate" v-model="selectEnd"
              @change="selectEndChange" />
          </div>
        </section>
        <section class="popFooter">
          <a class="button rounded cancel white" @click.prevent="pop.close"><span class="fM">取消</span></a>
          <a class="button rounded submit" @click.prevent="timeFilterConfirm(); pop.close();"><span
              class="fM">確認</span></a>
        </section>
      </FilterPopup>
      <!-- Popup (交易明細) -->
      <section id="popDetail" class="popup popDetail point_history" @click.prevent="orderDetailHide()">
        <div class="popBody" @click.stop>
          <a class="close" @click.prevent="orderDetailHide()"><font-awesome-icon
              icon="fas fa-times"></font-awesome-icon></a>
          <section class="popHeader">
            <span class="fL">交易明細</span>
          </section>
          <section class="popMain">
            <div class="flexH width middle cost">
              <span v-if="orderDetail.paymentAmount" class="fXL bold">NT${{ orderDetail.paymentAmount }}</span>
              <fragment v-if="orderDetail.points">
                <img src="@/assets/img/favicon.svg" />
                <span class="fXL bold primary">{{ orderDetail.points }}</span>
              </fragment>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易時間</span>
              <span class="fM">{{ displayOrderTime(orderDetail) }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">支付方式</span>
              <span class="fM">{{ displayPaymentType(orderDetail) }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易特店</span>
              <span class="fM">{{ orderDetail.storeName }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易編號</span>
              <span class="fM">{{ orderDetail.orderId }}</span>
            </div>
            <div class="flexH width between">
              <span class="fM gray">交易狀態</span>
              <span class="fM">{{
                displayOrderStatus(orderDetail.status)
              }}</span>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import FilterPopup from '@/components/FilterPopup.vue';

export default {
  name: 'PaymentRecord',
  components: { FilterPopup },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      paymentOrders: {},
      originPaymentOrders: {},
      timeFilterStart: null,
      timeFilterEnd: null,
      timeFilterOption: 'Monthly',
      displayedTimeFilterOption: '近一個月',
      selectStart: this.tsToDate(
        Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30
      ),
      selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
      selectStartErr: false,
      selectEndErr: false,
      orderDetail: {},
      timeFilterOpen: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    if (from.path === '/profile') {
      to.meta.parent = '/profile';
    } else if (from.path === '/' || from.path === '/record' || from.path === '/point-list' || from.path === '/payment-record') {
      to.meta.parent = localStorage.getItem('parentPath') || '/home';
    } else {
      to.meta.parent = '/home';
    }
    localStorage.setItem('parentPath', to.meta.parent);

    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        next('/error');
      }
    });
  },
  created() {
    this.getPaymentOrders()
      .then((getPaymentOrdersRes) => {
        this.paymentOrders = JSON.parse(JSON.stringify(getPaymentOrdersRes));
        this.originPaymentOrders = JSON.parse(
          JSON.stringify(getPaymentOrdersRes)
        );
      })
      .catch((err) => {
        console.log('err: ', err);
        this.router.push('/error');
      });
  },
  computed: {
    ...mapState(['user']),
    displayedOrders() {
      return this.paymentOrders.orders?.length ? this.paymentOrders.orders : [];
    },
  },
  mounted() {
  },
  methods: {
    goBackTo() {
      let parent = this.$route.meta.parent || '/home';
      this.$router.push(parent);
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm:ss');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    callPaymentOrdersAPI(start, end) {
      let endTime = end || Math.floor(Date.now() / 1000);
      let startTime = start || this.getStartTs(endTime, 30);

      var config = {
        method: 'get',
        url: `${this.apiHost}/payment/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders?startTs=${startTime}&endTs=${endTime}`,
        headers: {},
      };
      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getPaymentOrders(start, end) {
      return this.callPaymentOrdersAPI(start, end)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log('err:', err);
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    timeFilterConfirm() {
      if (this.timeFilterOption == 'Customize') {
        this.selectStartErr = this.selectStart ? false : true;
        this.selectEndErr = this.selectEnd ? false : true;
        if (this.selectStartErr || this.selectEndErr) return;
      }
      this.changeTimeFilterValue();
      let start = this.timeFilterStart;
      let end = this.timeFilterEnd;

      this.getPaymentOrders(start, end)
        .then((getPaymentOrdersRes) => {
          this.paymentOrders = JSON.parse(JSON.stringify(getPaymentOrdersRes));
          this.originPaymentOrders = JSON.parse(
            JSON.stringify(getPaymentOrdersRes)
          );
        })
        .catch((err) => {
          console.log('err: ', err);
          this.$router.push('/error');
        });
    },
    changeTimeFilterOption(option) {
      this.timeFilterOption = option;
    },
    changeTimeFilterValue() {
      switch (this.timeFilterOption) {
        case 'Weekly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 7);
          this.displayedTimeFilterOption = '近一週';
          break;
        case 'Monthly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 30);
          this.displayedTimeFilterOption = '近一個月';
          break;
        case 'Yearly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 365);
          this.displayedTimeFilterOption = '近一年';
          break;
        case 'Customize':
          this.timeFilterEnd =
            this.dateEndToTs(this.selectEnd) > Date.now() / 1000
              ? Date.now() / 1000
              : this.dateEndToTs(this.selectEnd);
          this.timeFilterStart = this.dateStartToTs(this.selectStart);
          this.displayedTimeFilterOption = '自訂時間';
          break;
        default:
          break;
      }
    },
    dateStartToTs(date) {
      return new Date(date).setHours(0, 0, 0, 0) / 1000;
    },
    dateEndToTs(date) {
      return new Date(date).setHours(23, 59, 59, 999) / 1000;
    },
    selectStartChange() {
      this.selectStartErr = false;
    },
    selectEndChange() {
      this.selectEndErr = false;
    },
    showOrderDetail(id) {
      popDetail.style.display = 'flex';
      this.orderDetail = this.displayedOrders.find(
        (order) => order.orderId === id
      );
    },
    orderDetailHide() {
      popDetail.style.display = 'none';
    },
    getPaymentImage(service, type) {
      let icon = null;
      let method = `${service}-${type}`;
      switch (method) {
        case 'tappay-apple':
          icon = require('@/assets/icon/p-applepay.svg');
          break;
        case 'tappay-google':
          icon = require('@/assets/icon/p-googlepay.svg');
          break;
        case 'tappay-line':
        case 'linepay-linepay':
          icon = require('@/assets/icon/p-linepay.svg');
          break;
        case 'easywallet-easywallet':
          icon = require('@/assets/icon/p-easywallet.svg');
          break;
        case 'tappay-jko':
          icon = require('@/assets/icon/p-jkopay.svg');
          break;
        case 'taiwanpay-twqrp':
          icon = require('@/assets/icon/p-taiwanpay.svg');
          break;
        default:
          if (service === null && type === null) {
            icon = require('@/assets/icon/point.svg');
          } else {
            icon = null;
          }
          break;
      }
      return icon;
    },
    displayPaymentType(order) {
      const {
        paymentService,
        paymentType,
        paymentMethod,
        amount,
        pointAmount,
      } = order;
      if (paymentMethod === 'offline') return '現場支付';
      if (amount === pointAmount) return '點數';
      let method = `${paymentService}-${paymentType}`;
      switch (method) {
        case 'tappay-apple':
          return 'Apple Pay';
        case 'tappay-google':
          return 'Google Pay';
        case 'tappay-line':
        case 'linepay-linepay':
          return 'Line Pay';
        case 'easywallet-easywallet':
          return '悠遊付';
        case 'tappay-jko':
          return '街口支付';
        case 'taiwanpay-twqrp':
          return '台灣 Pay';
        default:
          return '信用卡';
      }
    },
    displayOrderStatus(status) {
      return status === 'done' ? '已付款' : '已取消';
    },
    displayOrderTime(order) {
      const time =
        order.status === 'cancelled' ? order.cancelledAt : order.createdAt;
      return this.tsToDatetime(time);
    },
    getStartTs(endTs, days) {
      return (
        new Date(endTs * 1000).setHours(0, 0, 0, 0) / 1000 -
        1 * 60 * 60 * 24 * days
      );
    },
  },
};
</script>
